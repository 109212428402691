import { CustomError } from 'ts-custom-error';

type Params = {
	type: string | null;
	message: string | null;
};

export class CreateMembershipEnrollmentError extends CustomError {
	readonly type: string | null;

	constructor({ type, message }: Params) {
		super(message || 'Membership Enrollment Error');
		this.type = type;
	}
}
