import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	PaymentsSharedSavePaymentMethod,
	type PaymentsSharedSavePaymentMethodMutation,
	type PaymentsSharedSavePaymentMethodMutationVariables,
} from './savePaymentMethod.graphql';
import { SavePaymentMethodError } from './SavePaymentMethodError';

// TODO utilityContext should be a separate parameter
type Params = PaymentsSharedSavePaymentMethodMutationVariables & { utilityContext: UtilityContext };

export async function savePaymentMethod({
	input: {
		customer: { email, locale, countryCode },
		gateway,
		token,
		type,
		usage,
		gatewayData,
	},
	utilityContext,
}: Params): Promise<void> {
	const {
		gql: { fetch },
	} = utilityContext;
	try {
		const { data, errors } = await fetch<
			PaymentsSharedSavePaymentMethodMutation,
			PaymentsSharedSavePaymentMethodMutationVariables
		>({
			query: PaymentsSharedSavePaymentMethod,
			variables: {
				input: {
					customer: { email, locale, countryCode },
					gateway,
					token,
					type,
					usage,
					gatewayData,
				},
			},
			path: '/payments/savePaymentMethod',
			rejectOnError: false,
			batched: false, // to ensure this rate limited mutation is not batched
		});

		if (errors) throw new Error(JSON.stringify(errors));
		if (!data) throw new Error('No data returned from mutation.');
		if (data.savePaymentMethod.__typename === 'SavePaymentMethodError')
			throw new SavePaymentMethodError(data.savePaymentMethod);
	} catch (e) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
		(e as any).operationName = 'save_payment_method';
		throw e;
	}
}
