import { CustomError } from 'ts-custom-error';

type Param = {
	message: string | null;
	type?: string | null;
	declineCode: string | null;
};

export class SavePaymentMethodError extends CustomError {
	readonly type?: string | null;
	readonly declineCode: string | null;

	constructor({ message, type, declineCode }: Param) {
		super(message || 'SavePaymentMethodError');
		this.type = type;
		this.declineCode = declineCode;
	}
}
