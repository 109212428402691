import { CustomError } from 'ts-custom-error';

type Params = {
	type: string | null;
	declineCode?: string | null;
	message: string | null;
};

export class CreateChargePaymentError extends CustomError {
	readonly type: string | null;
	readonly declineCode?: string | null;

	constructor({ type, declineCode, message }: Params) {
		super(message || 'Charge Payment Error');
		this.type = type;
		this.declineCode = declineCode;
	}
}
