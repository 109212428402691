import { CustomError } from 'ts-custom-error';

type Params = {
	message?: string | null;
	code: string | null;
};

export class ConfirmGuestMembershipEnrollmentError extends CustomError {
	readonly code: string | null;

	constructor({ message, code }: Params) {
		super(message || 'Confirm Guest Membership Enrollment Error');
		this.code = code;
	}
}
