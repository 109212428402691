import { CustomError } from 'ts-custom-error';

type Param = {
	message: string | null;
	type?: string | null;
	gatewayErrorData: {
		code: string | null;
		type: string | null;
		declineCode: string | null;
	} | null;
};

export class SetupPaymentMethodError extends CustomError {
	readonly type?: string | null;
	readonly gatewayErrorData: {
		readonly code: string | null;
		readonly type: string | null;
		readonly declineCode: string | null;
	} | null;

	constructor({ message, type, gatewayErrorData }: Param) {
		super(message || 'SetupPaymentMethodError');
		this.type = type;
		this.gatewayErrorData = gatewayErrorData;
	}
}
